import React, { FC, useState } from 'react';
import NoSound from '../../../static/icons/volume_off_black_24dp.svg';
import Sound from '../../../static/icons/volume_up_black_24dp.svg';
import Vimeo from '@u-wave/react-vimeo';
import styled from 'styled-components';
import { pxToRem } from '../../utils/pxToRem';
import { AnimatePresence, motion, useTransform, useViewportScroll } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { imageVariants } from '../../templates/projectDetails';
import VimeoLogo from '../../../static/icons/vimeo-logo.svg';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
    videoId: string;
}
const VIMEO_URL = 'https://vimeo.com/';

const logo = {
    initial: {
        opacity: 0,
    },
    hidden: {
        opacity: 0,
    },
    enter: {
        opacity: [0, 1, 1, 0],
        transition: {
            duration: 5,
            delay: 2,
            times: [0, 0.1, 0.6, 1],
        },
    },
};

const curtain = {
    enter: {
        clipPath: [
            null,
            'circle(5% at 50% 50%)',
            'circle(40% at 50% 50%)',
            'circle(40% at 50% 50%)',
            'circle(100% at 50% 50%)',
        ],
        transition: {
            duration: 9,
            delay: 1,
            times: [0, 0.025, 0.15, 0.6, 1],
        },
    },
};

const loadingCircle = {
    enter: {
        backgroundColor: ['#ffffff', '#000000', '#ffffff'],
        transition: {
            duration: 0.7,
            repeat: Infinity,
        },
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.7,
        },
    },
};

export const Video: FC<Props> = ({ videoId }) => {
    const [volume, setVolume] = useState(0);
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: false,
    });
    const [isLoaded, setIsLoaded] = useState(false);

    const handleMute = () => {
        setVolume(volume ? 0 : 1);
    };

    return (
        <Wrapper variants={curtain} animate={isLoaded && 'enter'}>
            <Logo variants={logo} initial={'initial'} animate={isLoaded && 'enter'}>
                <StaticImage
                    src="../../images/logo-no-padding.png"
                    alt=""
                    loading={'eager'}
                    placeholder="blurred"
                    layout={'constrained'}
                />
            </Logo>
            <VideoContainer ref={ref}>
                <MuteButton onClick={handleMute} style={{}}>
                    {volume ? (
                        <Sound width={30} height={30} fill={'#fff'} />
                    ) : (
                        <NoSound width={30} height={30} fill={'#fff'} />
                    )}
                </MuteButton>
                <Vimeo
                    video={VIMEO_URL + videoId}
                    background={true}
                    loop={true}
                    autoplay={true}
                    controls={false}
                    responsive={true}
                    playsInline={true}
                    volume={volume}
                    dnt={true}
                    paused={!inView}
                    onPlay={() => setIsLoaded(true)}
                />
            </VideoContainer>
            <AnimatePresence initial={true}>
                <LoadingCircle variants={loadingCircle} initial={'enter'} animate={!isLoaded ? 'enter' : 'exit'} />
            </AnimatePresence>
        </Wrapper>
    );
};

const Logo = styled(motion.div)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0;
    width: 50%;
    max-width: 600px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper = styled(motion.div)`
    padding: 0;
    z-index: 0;
    position: relative;
    width: 100%;
    clip-path: circle(5% at 50% 50%);
    min-height: 200px;
`;

const LoadingCircle = styled(motion.div)`
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    transform: translate(-50% -50%);
    background-color: #ffffff;
    opacity: 1;
`;

const VideoContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    @media (max-width: ${pxToRem(1200)}) {
        height: 100%;
    }
`;

const MuteButton = styled.button`
    position: absolute;
    z-index: 1;
    bottom: ${pxToRem(30)};
    left: ${pxToRem(15)};
    background: none;
    border: none;
    cursor: pointer;
`;

const VimeoLink = styled.a`
    position: absolute;
    bottom: ${pxToRem(30)};
    left: ${pxToRem(65)};
    z-index: 1;
`;
