import React, { FC } from 'react';
import { ContentfulAsset } from '../../model/generated/graphql';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { pxToRem } from '../../utils/pxToRem';
import { Container } from '../movies/components/styles';
type Props = {
    data: ContentfulAsset[];
};
export const MembershipSection: FC<Props> = ({ data }) => {
    return (
        <Container maxWidth={1440}>
            <Wrapper>
                <TitleWrapper>
                    <Title>We are associated with</Title>
                </TitleWrapper>
                <ImgWrapper>
                    {data.map((el, i) => {
                        const image = getImage(el.gatsbyImageData);
                        return (
                            <>
                                <a href={el.description} target={'_blank'}>
                                    <Image key={i} image={image} alt={el.title} />
                                </a>
                            </>
                        );
                    })}
                </ImgWrapper>
            </Wrapper>
        </Container>
    );
};

const Title = styled.h2`
    font-size: 2rem;
    margin-bottom: 50px;
`;

const TitleWrapper = styled.div`
    text-align: center;
`;

const Image = styled(GatsbyImage)`
    height: ${pxToRem(100)};
    width: auto;
    margin: ${pxToRem(30)};
`;

const Wrapper = styled.div`
    margin: ${pxToRem(200)} auto ${pxToRem(200)};
    @media (max-width: ${pxToRem(800)}) {
        margin: ${pxToRem(100)} auto ${pxToRem(100)};
    }
`;

const ImgWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
`;
