import React, { FC, useState } from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import { Video } from '../modules/index/VideoHomePage';
import { ContentfulHome } from '../model/generated/graphql';
import styled from 'styled-components';
import { pxToRem } from '../utils/pxToRem';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import { EyeButton } from '../modules/index/EyeButton';
import { MembershipSection } from '../modules/index/Membership';
import { Container } from '../modules/movies/components/styles';
import { motion, useAnimation } from 'framer-motion';

interface IndexQueryProps {
    contentfulHome: ContentfulHome;
}
type IndexPageProps = PageProps<IndexQueryProps>;

const svg = {
    open: (i) => ({
        r: 10 + i * 5,
        transition: { staggerChildren: 0.02, delayChildren: 0.05 },
    }),
    close: {
        r: 100,
        transition: { staggerChildren: 0.02, delayChildren: 0.05 },
    },
};

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const RootIndex: FC<IndexPageProps> = ({ data, location }) => {
    const { aboutUs, banerToMovies, membership, portfolioFile } = data.contentfulHome;

    const [animate, setAnimate] = useState(false);

    const [hasBeenDownloaded, setHasBeenDownloaded] = useState(false);

    const activeLocale = 'en';
    const controls = useAnimation();

    return (
        <ParallaxProvider>
            <div>
                <Video videoId={'222642441'} />
                <Container maxWidth={1440}>
                    <AboutUsWrapper>
                        <h1>
                            WJTeam{'  '} · {'  '}Likaon
                        </h1>
                        {aboutUs?.childMarkdownRemark?.html && (
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: aboutUs?.childMarkdownRemark?.html,
                                }}
                            />
                        )}

                        <DownloadWrapper>
                            <a
                                download
                                href={portfolioFile.file.url}
                                target={'_blank'}
                                onClick={() => {
                                    controls.start('open');
                                    setHasBeenDownloaded(true);
                                }}
                                style={{ pointerEvents: hasBeenDownloaded ? 'none' : 'auto' }}
                            >
                                <DownloadLink3 hide={hasBeenDownloaded}>Download Portfolio</DownloadLink3>
                                <CircleSvg
                                    width={210}
                                    height={210}
                                    variants={svg}
                                    animate={controls}
                                    whileHover={!hasBeenDownloaded && 'open'}
                                >
                                    {[...Array(20)].map((el, i) => {
                                        return (
                                            <motion.circle
                                                key={i}
                                                cx={105}
                                                cy={105}
                                                r={95}
                                                strokeWidth={1}
                                                stroke={'#fff'}
                                                fill={'none'}
                                                variants={svg}
                                                custom={i}
                                            />
                                        );
                                    })}
                                </CircleSvg>
                            </a>
                        </DownloadWrapper>
                    </AboutUsWrapper>
                </Container>

                <MoviesBannerParallax
                    layers={[{ image: `${banerToMovies.url}?w=1920&h=1080&q=90&fm=webp`, speed: -20 }]}
                >
                    <EyeLink to={`/${activeLocale === 'en' ? 'en' : 'pl'}/projects/lemon-and-berry`}>
                        <EyeButton />
                    </EyeLink>
                    <LayerMask />
                </MoviesBannerParallax>

                <MembershipSection data={membership} />
            </div>
        </ParallaxProvider>
    );
};

export default RootIndex;

const DownloadLink3 = styled.p<{ hide: boolean }>`
    pointer-events: none;
    font-size: 2rem;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 2;
    transition: opacity 300ms ease-in-out;
    color: #fff;
    opacity: ${(props) => (props.hide ? 0 : 1)};
    &:hover {
        text-shadow: 0 0 4px #fff;
        opacity: 0;
    }
`;

const DownloadWrapper = styled.div`
    width: 220px;
    height: 210px;
    margin: 100px auto;
    position: relative;
    overflow: visible;
    white-space: nowrap;
    &:hover ${DownloadLink3} {
        opacity: 0;
    }
`;

const CircleSvg = styled(motion.svg)`
    cursor: pointer;
`;

const DownloadLink = styled.a`
    font-size: 2rem;
    position: relative;
    display: inline-block;
    margin-top: 100px;
    cursor: pointer;
    z-index: 2;
    transition: transform 1300ms ease-in-out;
    color: #fff;
    &:hover {
        text-shadow: 0 0 4px #fff;
        &:before {
            box-shadow: inset -0px 0px 15px 5px rgba(255, 255, 255, 0.1);
            transform: translateX(-50%) translateY(-25%) scale(1.1);
        }
    }
    &:before {
        content: '';
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 4px solid #666;
        transform: translateX(-50%) translateY(-25%) scale(1);
        z-index: -1;
        position: absolute;
        left: 50%;
    }
`;

const LayerMask = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
`;

const EyeLink = styled(Link)`
    cursor: pointer;
    position: relative;
    z-index: 2;
`;

const MoviesBannerParallax = styled(ParallaxBanner)`
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: ${pxToRem(1200)}) {
        height: 600px;
    }
`;

const AboutUsWrapper = styled.div`
    max-width: 800px;
    margin: 10vh auto 10vh;
    text-align: center;
    padding: 24px;

    @media (max-width: ${pxToRem(1200)}) {
        margin: 7vh auto 7vh;
    }

    @media (max-width: ${pxToRem(800)}) {
        margin: 5vh auto 5vh;
    }

    & h1 {
        font-size: 3rem;
        @media (max-width: ${pxToRem(800)}) {
            font-size: 2rem;
        }
    }
`;

export const Description = styled.div`
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.5;
    padding: 0 12px;
    text-align: justify;
    @media (max-width: ${pxToRem(1440)}) {
        font-size: 1.2rem;
    }
    @media (max-width: ${pxToRem(800)}) {
        font-size: 1rem;
    }
`;

export const pageQuery = graphql`
    query HomeQuery($node_locale: String) {
        contentfulHome(contentful_id: { eq: "34nbxTzgnX88ech7AfmRaX" }, node_locale: { eq: $node_locale }) {
            title
            description {
                raw
            }
            aboutUs {
                childMarkdownRemark {
                    html
                }
            }
            banerToMovies {
                title
                description
                gatsbyImageData(width: 1920, height: 1080, quality: 90, placeholder: BLURRED, formats: [AUTO, WEBP])
                url
            }
            membership {
                title
                description
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, height: 100)
            }
            portfolioFile {
                file {
                    url
                }
            }
        }
    }
`;
