import React, { FC } from 'react';
import styled, { css } from 'styled-components';

export const EyeButton: FC = () => {
    return (
        <Button>
            <TextCircle viewBox="0 0 500 500">
                <title>Check our projects</title>
                <defs>
                    <path id="textcircle" d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"></path>
                </defs>
                <text>
                    <textPath aria-label="Check our projects" xlinkHref={'#textcircle'} textLength="900">
                        Check our projects
                    </textPath>
                </text>
            </TextCircle>
            <Eye aria-hidden="true" width="70" height="70" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
                <EyeOuter d="M10.5 35.308c5.227-7.98 14.248-13.252 24.5-13.252s19.273 5.271 24.5 13.252c-5.227 7.98-14.248 13.253-24.5 13.253s-19.273-5.272-24.5-13.253z" />
                <EyeLashesUp d="M35 8.802v8.836M49.537 11.383l-3.31 8.192M20.522 11.684l3.31 8.192" />
                <EyeLashesDown d="M35 61.818v-8.836 8.836zM49.537 59.237l-3.31-8.193 3.31 8.193zM20.522 58.936l3.31-8.193-3.31 8.193z" />
                <EyeIris cx="35" cy="35.31" r="5.221" />
                <EyeInner cx="35" cy="35.31" r="10.041" />
            </Eye>
        </Button>
    );
};

const EyeCommon = css`
    stroke: #fff;
    fill: none;
    stroke-width: 1.5px;
`;

const EyeOuter = styled.path`
    ${EyeCommon}
`;

const EyeLashesUp = styled.path`
    ${EyeCommon}
`;

const EyeLashesDown = styled.path`
    ${EyeCommon};
    opacity: 0;
`;
const EyeIris = styled.circle`
    fill: #fff;
`;
const EyeInner = styled.circle`
    ${EyeCommon};
    //fill: #fff;
`;

const Eye = styled.svg`
    position: absolute;
    z-index: 2;
    width: 60px;
    height: 60px;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
`;

const TextCircle = styled.svg`
    position: relative;
    display: block;
    width: 200px;
    & text {
        font-size: 42px;
        text-transform: uppercase;
        fill: #fff;
    }
    & textPath {
        letter-spacing: 17px; /* Firefox needs this */
    }
`;

const Button = styled.div`
    position: relative;
    padding: 0;
    background: none;
    -webkit-clip-path: circle(40% at 50% 50%);
    clip-path: circle(40% at 50% 50%);
    &:focus-visible {
        background: #443ffc;
    }
    &:hover ${TextCircle} {
        animation: rotateIt 7s linear infinite;
    }
    &:hover ${EyeLashesUp}, &:hover ${EyeInner}, &:hover ${EyeIris} {
        animation: blinkHide 2s step-end infinite;
    }
    &:hover ${EyeLashesDown} {
        animation: blinkShow 2s step-end infinite;
    }
`;
